import React from "react";
import { Link } from "react-router-dom";
import "./About.css";
import resume from "./AndrewLee_Resume_2023.pdf";
import me from "./Images/DSC01034.png";
export default function About() {
  return (
    <div className="about-div">
      <h2 className="about-heading">About</h2>
      <section className="bio">
        <img src={me} />
        <h1 className="bio-name">Andrew Lee</h1>
        <a>
          Email: <a href="mailto: alee14@bu.edu">alee14@bu.edu</a>
        </a>
        <br />
        <a href={resume} target="_blank">
          Resume
        </a>
        <p className="bio-desc">
          I am a senior at Boston University studying math and computer science
          with the goal of becoming a software engineer. I am interested in distributed 
          system design and cloud computing. When I am not coding, I like to skateboard, 
          throw clay, and cook!{" "}
        </p>
      </section>
    </div>
  );
}
