import React, { useState } from "react";
import { Route, Link, Switch } from "react-router-dom";
import useScrollPosition from "./useScrollPosition";
import "./App.scss";

import About from "./About";
import WordCloud from "./WordCloud";
import Projects from "./Projects";
import Essay from "./Essay";

function App() {
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => setOffsetY(window.pageYOffset);

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }

  const list = [
    ["PYTHON", 9, "language"],
    ["JavaScript", 9, "language"],
    ["Java", 6, "language"],
    ["REACT", 8, "framework"],
    ["Ruby On Rails", 7, "framework"],
    ["SQL", 5, "language"],
    ["HTML", 6, "language"],
    ["CSS", 7, "language"],
    ["Express", 5, "framework"],
    ["Ruby", 8, "language"],
    ["C++", 3, "language"],
    ["Apache Kafka", 4, "framework"],
    ["TensorFlow", 4, "library"],
    ["Adobe Suite", 7, "software"],
    ["Tableau", 8, "software"],
    ["NumPy", 7, "library"],
    ["Pandas", 8, "library"],
    ["NetworkX", 5, "library"],
    ["SciPy", 5, "library"],
    ["Plotly", 7, "library"],
    ["Matplotlib", 7, "library"],
    ["Tweepy", 7, "library"],
    ["Chakra UI", 7, "library"],
    ["Microsoft Suite", 8, "software"],
    ["Figma", 5, "software"],
  ];

  shuffle(list);

  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = currPos.y > -860 || currPos.y <= -2700;

    isShow !== true
      ? window.removeEventListener("scroll", handleScroll)
      : window.addEventListener("scroll", handleScroll);
  }, []);

  const renderContent = () => (
    <>
      <h1 className="name">ANDREW LEE</h1>
      <section className="content">
        <h2 className="content-header">
          <Link to="/proyecto" className="spanish-essay-link-home">Click here to read my biography in spanish!</Link>
        </h2>
        <h2 className="content-header">
          Currently seeking new grad roles for 2024
        </h2>
      </section>

      <div>
        <WordCloud list={list} size={0.9} />
      </div>
      <Projects />

      <div className="about-section">
        <About />
      </div>
    </>
  );

  return (
    <div>
      <Switch>
        <Route exact path="/">
          <section className="Parallax">
            <div
              className="Parallax__background"
              style={{ transform: `translateY(-${offsetY * 0.2}px)` }}
            />
            <div
              className="Parallax__backgroundtwo"
              style={{ transform: `translateY(${offsetY * 0.15}px)` }}
            />
            <div
              className="Parallax__backgroundcircles"
              style={{ transform: `translateY(${offsetY * -0.0}px)` }}
            />
            <div
              className="Parallax__backgroundtrans"
              style={{ transform: `translateY(${offsetY * -0.2}px)` }}
            />
            <div className="Parallax__content">{renderContent()}</div>
          </section>
        </Route>
        <Route exact path="/proyecto">
          <section className="Parallax">
            <Essay/>
          </section>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
