import { useState } from "react";
import "./WordCloud.css";

function WordCloud({ list, size }) {
  const [organize, setOrganize] = useState(true);
  //determines overall size

  //proficency 1-10 determines fontsize  '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')
  //category color random for each '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0') or randomHSL();
  function organizeWC() {
    setOrganize(!organize);
  }
  function randomHSL() {
    return "hsla(" + ~~(360 * Math.random()) + "," + "90%," + "40%,1)";
  }

  const dict = {};

  const newlst = list.map((item) => {
    if (dict[item[2]] == null) {
      dict[item[2]] = randomHSL();
    }

    return [item[0], item[1] * item[1] * size, dict[item[2]], item[1]];
  });

  const sortByType = {};
  list.forEach((item) => {
    if (sortByType[item[2]] == null) {
      sortByType[item[2]] = [];
      sortByType[item[2]].push(item[0]);
    } else {
      sortByType[item[2]].push(item[0]);
    }
  });
  console.log(sortByType);

  return (
    <div className="wordcloud-container">
      <h2 className="heading">TECH STACK</h2>
      {organize ? (
        <h1 className="wc-btn" onClick={organizeWC}>
          Click Me
        </h1>
      ) : (
        <h1 className="wc-btn" onClick={organizeWC}>
          Click Me
        </h1>
      )}

      {organize ? (
        <div className="wordcloud-div">
          <div className="wordcloud-key">
            <section className="span-container">
              <span className="cat-label" style={{ color: dict["library"] }}>
                LIBRARY
              </span>
              <span className="cat-label" style={{ color: dict["framework"] }}>
                FRAMEWORK
              </span>
              <span className="cat-label" style={{ color: dict["language"] }}>
                LANGUAGE
              </span>
              <span className="cat-label" style={{ color: dict["software"] }}>
                SOFTWARE
              </span>
            </section>
          </div>
          <ul className="wordcloud">
            {newlst.map((item) => (
              <li
                key={item[0]}
                className="tooltip"
                style={{ fontSize: item[1], color: item[2] }}
              >
                {item[0]}
                <span className="tooltiptext">Confidence: {item[3]}/10</span>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="org-wc">
          <section className="span-container"></section>
          <div className="list-container">
            <div className="org-div">
              <span className="cat-label" style={{ color: dict["library"] }}>
                LIBRARY
              </span>

              <ul>
                {sortByType["library"].map((item) => (
                  <li
                    className="organize-item"
                    style={{ color: dict["library"] }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div className="org-div">
              <span className="cat-label" style={{ color: dict["framework"] }}>
                FRAMEWORK
              </span>
              <ul>
                {sortByType["framework"].map((item) => (
                  <li
                    className="organize-item"
                    style={{ color: dict["framework"] }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>

            <div className="org-div">
              <span className="cat-label" style={{ color: dict["language"] }}>
                LANGUAGE
              </span>
              <ul>
                {sortByType["language"].map((item) => (
                  <li
                    className="organize-item"
                    style={{ color: dict["language"] }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>

            <div className="org-div">
              <span className="cat-label" style={{ color: dict["software"] }}>
                SOFTWARE
              </span>
              <ul>
                {sortByType["software"].map((item) => (
                  <li
                    className="organize-item"
                    style={{ color: dict["software"] }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default WordCloud;
