// Essay.js
import React from 'react';
import { Link } from "react-router-dom";
import './Essay.css';
import me2 from './Images/LS212_me.png';
import pic1 from './Images/IMG_8446.jpg';
import pic2 from './Images/IMG_8447.jpg';
import pic3 from './Images/IMG_8448.jpg';
import pic4 from './Images/IMG_8449.jpg';
import essay from './Images/spanish-essay.png';

function Essay() {
    const essayTitle = "Mi Biografía";
    const essayContent = `
    El 20 de agosto de dos mil, yo, Andrew Lee, nací en Coventry, Rhode Island. Rhode Island es el lugar donde pasé los años de mi niñez. Creciendo en los suburbios, mis amigos y yo jugábamos en la naturaleza; por lo tanto, soy muy activo. Mis actividades favoritas son Patinaje, el baloncesto, correr y explorar lugares cercanos. En consecuencia, me gustan mucho los días soleados y no me gusta la lluvia. 
Yo soy muy activo, pero por otro lado, estudió ciencias de la computación y matemáticas, por lo que la mayor parte de mi tiempo se pasa en mi casa. Me ha fascinado la computadora porque hago programas útiles que en resumidas cuentas hacen mi vida más fácil. Mi interés en la programación de computadoras se debe a mi personalidad analítica. 
Mis pasiones por las actividades al aire libre y las actividades de interior me dan una perspectiva muy equilibrada. Como me gusta decirlo, tengo oportunidades tanto para el mundo tangible como para el abstracto. En la vida cree que es muy importante tener ambos. Por supuesto, me gusta combinar los dos. Hace poco estuve con mis amigos y estábamos pensando en un viaje a Japón. Pero pensamos mucho en esto y rara vez tenemos éxito en nuestros planes.
Debido a que nunca lo presupuestamos ni lo planificamos de manera proactiva, terminamos por no ir. Aquí es donde mis dos mundos chocan. Con mi pasión por salir con mis amigos y explorar el mundo y mi pasión por la ingeniería de software, puedo usar ambas pasiones en una. ¡Ahora estoy usando mis habilidades para crear una aplicación que ayudará a mis amigos a presupuestar un viaje juntos!
Estoy muy satisfecho con este equilibrio en mi vida y me trae mucha alegría. Al igual que mi pasión por diseñar y crear para otros, tengo una energía creativa para cualquier cosa, como la música, la pintura, la escultura e incluso la actuación. Desde muy pequeña mi madre me inculcó el arte y el pensamiento creativo.
Mi mamá siempre me llevaba al campamento de arte en RISD. Fue diseñadora de juguetes para Hasbro, por lo que el arte y el diseño son una parte importante de ella. Viste ropa muy colorida y siempre me anima a ser yo mismo en cualquier forma que elija.
Le atribuyo mucho de lo que soy a mi mamá. Ella me ha moldeado de muchas maneras. Mi infancia, que me permitió explorar libremente, me convirtió en la persona que soy hoy.

    `;

    return (
        <div className="main-essay-container">
            
                        <Link to="/" className="spanish-essay-link">back home</Link>
        <div className="essay-container">

            <img src={me2} alt="Portrait" className="essay-image" />
            <h1 className="essay-title">{essayTitle}</h1>
            <img src={essay} alt="essay" className="essay-doc-image" />
            {/* image section */}
            <div className="image-grid">
    {[
        { src: pic1, caption: "Aquí estoy patinando. Es muy desafiante pero gratificante.", alt: "Description 1" },
        { src: pic2, caption: "Aquí hay una foto de mi mamá. Lleva un vestido que ella misma hizo.", alt: "Description 2" },
        { src: pic3, caption: "Hace seis meses fui de excursión a las montañas blancas. Además mi cabello es rubio.", alt: "Description 3" },
        { src: pic4, caption: "Fui de excursión a Los Ángeles. Este viaje me cambió la vida. Quiero vivir allí algún día.", alt: "Description 4" },
    ].map((image, index) => (
        <figure key={index} className="grid-item">
            <img src={image.src} alt={image.alt} />
            <figcaption>{image.caption}</figcaption>
        </figure>
    ))}
</div>
<div className="video-container">
<iframe 
    style={{ borderRadius: '12px' }} 
    title="Descriptive Title for Spotify Track" 
    src="https://open.spotify.com/embed/track/1RKgTID6LoCDMvXxX70vyU?utm_source=generator" 
    width="100%" 
    height="352" 
    frameBorder="0" 
    allowFullScreen="" 
    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
    loading="lazy">
</iframe>
<iframe 
    style={{ borderRadius: '12px' }} 
    title="Descriptive Title for Spotify Track" 
    src="https://open.spotify.com/embed/track/4VUXAoJESfRV3ceUYtzfpQ?utm_source=generator" 
    width="100%" 
    height="352" 
    frameBorder="0" 
    allowFullScreen="" 
    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
    loading="lazy">
</iframe>
<iframe 
    style={{ borderRadius: '12px' }} 
    title="Descriptive Title for Spotify Track" 
    src="https://open.spotify.com/embed/track/7ECeHzfdSTSiUWKag8pIXt?utm_source=generator" 
    width="100%" 
    height="352" 
    frameBorder="0" 
    allowFullScreen="" 
    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
    loading="lazy">
</iframe>
<iframe title="Descriptive Title for YouTube Video" width="560" height="315" src="https://www.youtube.com/embed/zZHhCOQJvIM?si=yqisZZ_TfTMEYur9" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen loading="lazy"></iframe>
           </div>
        </div>
        
        </div>
    );
}

export default Essay;
