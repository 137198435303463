import "./Projects.css";
import BUTOO from "./Images/BU-TOO.png";
import IMPORIUM from "./Images/Imporium.png";
import CRYPTONTW from "./Images/CRYPTO-NTW.png";
import DAILYNODE from "./Images/DAILYNODE.png";
import PORTFOLIO from "./Images/PORTFOLIO.png";
import TWTR from "./Images/TwitterSentiment.png";
import VolSurf from "./Images/VolSurf.png";
import PROMPTU from "./Images/promptu.png";

function Projects() {
  return (
    <div className="Projects-main">
      <h2 className="proj-heading">PROJECTS</h2>
      <div className="hor-scroll">
        <ul className="project-container">
          <li className="project-box">
            <h3 className="card-title">
              Bidirectional network of cryptocurrency exchange pairs
            </h3>
            <h2>Technologies: Python, NetworkX</h2>
            <a
              href="https://github.com/andrewleenyk/Cryptocurrency-Exchange-Volume-Bidirectional-Network"
              target="_blank"
            >
              <img
                className="project-img"
                src={CRYPTONTW}
                alt="project image"
              />
            </a>
          </li>
          <li className="project-box">
            <h3 className="card-title">Twitter Sentiment Analysis</h3>
            <h2>Technologies: Python, Plotly, Pandas, Numpy, Tweepy</h2>
            <a
              href="https://github.com/andrewleenyk/Twitter-API-Sentiment-Analysis-and-Data-Visuals"
              target="_blank"
            >
              <img className="project-img" src={TWTR} alt="project image" />
            </a>
          </li>
          <li className="project-box">
            <h3 className="card-title">Option Volatility Surface</h3>
            <h2>Technologies: Python, Numpy, SciPy, Plotly</h2>
            <a
              href="https://github.com/andrewleenyk/Option-Volatility-Surface"
              target="_blank"
            >
              <img className="project-img" src={VolSurf} alt="project image" />
            </a>
          </li>
          <li className="project-box">
            <h3 className="card-title">NFT Marketplace Full CRUD</h3>
            <h2>Technologies: React, Express, JS</h2>
            <a href="https://github.com/dayi2007/imporium" target="_blank">
              <img className="project-img" src={IMPORIUM} alt="project image" />
            </a>
          </li>
          <li className="project-box">
            <h3 className="card-title">Anonymous Sexual Assault Story Forum</h3>
            <h2>Technologies: React, Airtable API, JS, Material UI</h2>
            <a href="https://github.com/andrewleenyk/-BUtoo" target="_blank">
              <img className="project-img" src={BUTOO} alt="project image" />
            </a>
          </li>
          <li className="project-box">
            <h3 className="card-title">Tech-themed Blog Full CRUD</h3>
            <h2>Technologies: React, Ruby, JS, PSQL</h2>
            <a href="https://github.com/andrewleenyk/WEB3-Blog" target="_blank">
              <img
                className="project-img"
                src={DAILYNODE}
                alt="project image"
              />
            </a>
          </li>
          <li className="project-box">
            <h3 className="card-title">Portfolio Website</h3>
            <h2>Technologies: React, CSS, Adobe Illustrator</h2>
            <a
              href="https://github.com/andrewleenyk/portfolio-site"
              target="_blank"
            >
              <img
                className="project-img"
                src={PORTFOLIO}
                alt="project image"
              />
            </a>
          </li>
          <li className="project-box">
            <h3 className="card-title">promptu</h3>
            <h2>Technologies: React, Firebase, Chakra UI</h2>
            <a href="https://github.com/serencha/promptu" target="_blank">
              <img className="project-img" src={PROMPTU} alt="project image" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default Projects;
